html,
body {
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
}
.greeting {
	font-family: "Lato", sans-serif;
	position: absolute;
	left: 0;
	top: 40%;
	width: 100%;
	text-align: center;
	opacity: .5;
}
.greeting span.border {
	background-color: #111;
	font-size: 35px;
	letter-spacing: 10px;
	padding: 1rem 1rem;
	color: #fff;
}
.border-2 {
	padding-top: .75rem;
}
.brand {
	position: fixed;
	bottom: 5%;
	right: 12%;
	font-size: 15px;
	color: #fff;
}
.brand span {
	padding: 1em;
}
@media only screen and (max-width: 700px) {
	.brand {
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
@media only screen and (max-width: 425px) {
	.brand {
		bottom: 2%;
	}
}
@media only screen and (max-width: 425px) {
	#contact {
		right: 2.65rem;
		align-items: center;
		text-align: center;
		padding-bottom: 1rem;
	}
}
.tag {
	font-family: 'Cabin Sketch', cursive;
	font-size: 40px;
	display: flex;
	border: 2px dotted #ff9900;
	background-color: #e8e8e8;
	height: 50px;
	width: 155px;
	justify-content: center;
	align-items: center;
	margin: .125em .2em 0;
}
.tag span {
	margin-top: 3px;
}
.trade {
	font-size: 10px;
}
.logo h1 {
	color: #383a3c;
}
#home {
	background-image: url('./assets/bg.jpg');
	height: 100vh;
}
#contact {
	position: fixed;
	bottom: 3.8%;
	left: 10%;
	color: #fff;
	font-size: 1.2em;
}
#resume {
	padding-bottom: 2rem;
}
.name {
	font-family: "Lato", sans-serif;
	font-size: 2rem;
	font-weight: 100;
}
.logos {
	display: flex;
	justify-content: space-evenly;
	padding-bottom: 1rem;
	font-size: 3rem;
}
.logos i {
	color: #97979b;
}
.logos i:hover {
	transition: all .5s ease-in;
	color: #fff;
}
.logo {
	align-items: flex-start;
}
.logo,
header aside,
ul.skills {
	display: flex;
}
fieldset,
hr {
	border: 0;
	padding: 0;
}
.hobbies li,
.time-line,
header aside ul,
ul.skills {
	list-style-type: none;
}
hr {
	display: block;
	height: 1px;
	border-top: 1px solid #ccc;
	margin: 1em 0;
}
fieldset {
	margin: 0;
}
textarea {
	resize: vertical;
}
abbr[title] {
	text-decoration: dotted;
}
@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.container,
.container-fluid {
	margin: 0 auto;
	max-width: 1280px;
	background: #fefefe;
	animation-duration: 1s;
	animation-name: fadein;
	min-height: 0;
}
h1,
h2,
h3,
h4 {
	font-family: Oswald, sans-serif;
	text-transform: uppercase;
	font-size: 1.2rem;
}
a {
	color: #444;
}
a:hover {
	transition: all .5s ease-in;
	color: #222;
	text-decoration: none;
}
header {
	display: flex;
	margin: 50px 0 20px;
}
header h1 {
	display: inline-flex;
	flex-direction: column;
	font-size: 2rem;
	white-space: nowrap;
}
.logo,
header aside,
ul.skills {
	display: flex;
}
.logo em,
article > p > span {
	color: #ff9900;
}
header aside ul li,
header h1 span {
	font-size: .95rem;
}
header h1 span {
	font-family: 'Open Sans', sans-serf;
	text-transform: lowercase;
	color: #717171;
}
header h2 {
	color: #383a3c;
}
header aside ul {
	width: auto;
	margin-bottom: 0;
	padding: 0;
}
header aside ul li i {
	font-size: 1.2rem;
	margin: 0 15px;
}
article header {
	border-bottom: 1px solid #222;
	margin-bottom: 10px;
	padding-left: 5px;
}
article h2 i,
article header i {
	font-size: 1.2rem;
}
article header h2 {
	height: 29px;
	line-height: 29px;
	margin-bottom: 0;
}
article p {
	padding: 5px;
}
.content li,
article p {
	line-height: 1.5rem;
	font-size: .9rem;
}
aside em {
	color: #c9c9c9;
}
ul.skills {
	flex-wrap: wrap;
	padding: 0;
}
.skills li {
	flex-basis: 33%;
	display: inline-flex;
	flex-direction: column-reverse;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	padding-bottom: 10px;
}
.skills li .icon-label {
	margin: 0 15px;
	cursor: pointer;
}
.skills li .devicons {
	transition: color .15s ease-in;
}
.skills i {
	font-size: 2.25rem;
	width: 60px;
	text-align: center;
}
.skill-Photoshop:hover {
	color: #32a8ff;
}
.skill-PHP:hover {
	color: #6c7eb7;
}
.skill-Vue:hover {
	color: #42b883;
}
.skill-JavaScript:hover {
	color: #f0db4f;
}
.skill-NodeJS:hover {
	color: #598558;
}
.skill-React:hover {
	color: #00d9ff;
}
.skill-HTML5:hover {
	color: #f16529;
}
.skill-CSS3:hover {
	color: #1c88c7;
}
.skill-NGINX:hover {
	color: #009639;
}
.skill-Mongo:hover {
	color: #68b245;
}
.skill-Bootstrap:hover {
	color: #563d7c;
}
.skill-Git:hover {
	color: #f05033;
}
.skill-Handlebars:hover {
	color: #f79622;
}
.skill-jQuery:hover {
	color: #78cff5;
}
.skill-Heroku:hover {
	color: #9e7cc2;
}
.skill-Less:hover {
	color: #1d365d;
}
.skill-TypeScript:hover {
	color: #3078c6;
}
.skill-AWS:hover {
	color: #ec912e;
}
.skill-Babel:hover {
	color: #f4da55;
}
.skill-Docker:hover {
	color: #1d97ed;
}
.skill-Wordpress:hover {
	color: #43a2c3;
}
.time-line {
	position: relative;
	padding-left: 140px;
}
.time-line:before {
	content: "";
	width: 3px;
	height: 100%;
	position: absolute;
	background: #d1d2d4;
	background: -moz-linear-gradient(top, #fefefe 0, #d1d2d4 10%, #d1d2d4 90%, #fefefe 100%);
	background: -webkit-linear-gradient(top, #fefefe 0, #d1d2d4 10%, #d1d2d4 90%, #fefefe 100%);
	background: linear-gradient(to bottom, #fefefe 0, #d1d2d4 10%, #d1d2d4 90%, #fefefe 100%);
	left: 105px;
	top: 0;
}
.time-line p {
	padding: 0;
}
.time-line > li:nth-child(1) > div {
	padding-top: 40px;
}
.time-line div > ul {
	margin-bottom: 15px;
}
.time-line .time-point {
	display: block;
	width: 13px;
	height: 13px;
	margin-top: 3px;
	border-radius: 100%;
	border: 3px solid #d1d2d4;
	background: #fefefe;
	position: absolute;
	left: 100px;
}
.time-line .time-date,
.time-line .time-date-subtitle {
	position: absolute;
	left: 0;
	font-family: Oswald, sans-serif;
	font-size: 1rem;
	margin-top: -4px;
	width: 90px;
	text-align: right;
}
.time-line .time-date-subtitle {
	font-family: 'Open Sans', sans-serif;
	font-size: .85rem;
	margin-top: 18px;
	max-width: 100px;
	line-height: 1rem;
}
.time-date-subtitle {
	color: #ff9900;
}
.hobbies {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	flex-direction: row;
	padding: 0;
}
.hobbies span {
	margin-right: .6em;
}
.hobbies li {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 10px;
}
.hobbies li i {
	border-radius: 100%;
	border: 1px solid #222;
	font-size: 2rem;
	padding: 30px;
	margin: 0 0 10px;
}
.fa-github:before {
	content: "\f09b";
}
.fa-linkedin:before {
	content: "\f08c";
}
.fa-school {
	content: "\f549";
}
.fa-bullseye {
	content: "\f549";
}
.fa-database {
	content: "\f549";
}
.fa-magic {
	content: "\f549";
}
.fa-school {
	content: "\f549";
}
.fa-beer {
	content: "\f0fc";
}
.fa-comments {
	content: "\f086";
}
.fa-hat-wizard {
	content: "\f6e8";
}
.content p:before {
	content: "•";
	margin: 0 .5rem 0 0;
	color: #ff9900;
}
[class*="fa-"] {
	position: relative;
	top: 5px;
	right: 5px;
}
[class*="devicon-:before"] {
	font-size: 1px;
}
.mail:hover,
.gitLogo:hover {
	color: #ff9900;
}
.mail {
	padding-bottom: .5em;
	top: 3px;
}
.gitLogo {
	position: relative;
	right: 5px;
	top: 3px;
}
.info {
	display: flex;
	justify-content: flex-end;
}
@media print {
	thead {
		display: table-header-group;
	}
	img,
	tr {
		page-break-inside: avoid;
	}
	img {
		max-width: 100% !important;
	}
	h2,
	h3,
	p {
		orphans: 3;
		widows: 3;
	}
	h2,
	h3 {
		page-break-after: avoid;
	}
}
a {
	text-decoration: none;
}
a:hover {
	color: #ff9900;
}
.orange {
	color: #ff9900;
}
